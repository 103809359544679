import { createReducer } from "@reduxjs/toolkit";
import _union from "lodash/union";

import * as actions from "./CampingCarQuote.actions";

const initialState: CampingCarQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: "",
  isDeleting: "",
};

export default createReducer<CampingCarQuoteState>(initialState, {
  [actions.submitCampingCarQuote.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.submitCampingCarQuote>,
  ): CampingCarQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitCampingCarQuoteError.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.submitCampingCarQuoteError>,
  ): CampingCarQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitCampingCarQuoteSuccess.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.submitCampingCarQuoteSuccess>,
  ): CampingCarQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchCampingCarQuote.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.fetchCampingCarQuote>,
  ): CampingCarQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchCampingCarQuoteError.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.fetchCampingCarQuoteError>,
  ): CampingCarQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchCampingCarQuotesSuccess.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.fetchCampingCarQuotesSuccess>,
  ): CampingCarQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<CampingCarQuote> }>(
      (acc, campingCarQuote) => ({
        ...acc,
        [campingCarQuote.id]: campingCarQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((campingCarQuote) => campingCarQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateCampingCarQuote.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.updateCampingCarQuote>,
  ): CampingCarQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateCampingCarQuoteError.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.updateCampingCarQuoteError>,
  ): CampingCarQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.updateCampingCarQuoteSuccess.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.updateCampingCarQuoteSuccess>,
  ): CampingCarQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: "",
  }),
  [actions.saveCampingCarQuote.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.saveCampingCarQuote>,
  ): CampingCarQuoteState => ({
    ...state,
    isUpdating: action.payload.campingCarQuoteId,
  }),
  [actions.saveCampingCarQuoteError.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.saveCampingCarQuoteError>,
  ): CampingCarQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.saveCampingCarQuoteSuccess.type]: (
    state: CampingCarQuoteState,
    action: ReturnType<typeof actions.saveCampingCarQuoteSuccess>,
  ): CampingCarQuoteState => ({
    ...state,
    isUpdating: "",
  }),
});
