import { createAction } from "@reduxjs/toolkit";

export const submitAutoQuote = createAction<FormAutoValues>("CREATE_AUTO_QUOTE_REQUEST");
export const submitAutoQuoteSuccess = createAction<WithId<AutoQuote>>("CREATE_AUTO_QUOTE_SUCCESS");
export const submitAutoQuoteError = createAction<Error>("CREATE_AUTO_QUOTE_ERROR");

export const fetchAutoQuote = createAction<string>("FETCH_AUTO_QUOTE_REQUEST");
export const fetchAutoQuoteError = createAction<Error>("FETCH_AUTO_QUOTE_ERROR");
export const fetchAutoQuotesSuccess = createAction<WithId<AutoQuote>[]>("FETCH_AUTO_QUOTES_SUCCESS");

export const updateAutoQuote = createAction<WithId<AutoQuote>>("UPDATE_AUTO_QUOTE_REQUEST");
export const updateAutoQuoteSuccess = createAction<WithId<AutoQuote>>("UPDATE_AUTO_QUOTE_SUCCESS");
export const updateAutoQuoteError = createAction<Error>("UPDATE_AUTO_QUOTE_ERROR");

export const saveAutoQuote = createAction("SAVE_AUTO_QUOTE_REQUEST", (autoQuoteId: string, tarif: Tarif) => ({
  payload: { autoQuoteId, tarif },
}));
export const saveAutoQuoteSuccess = createAction("SAVE_AUTO_QUOTE_SUCCESS");
export const saveAutoQuoteError = createAction<Error>("SAVE_AUTO_QUOTE_ERROR");
