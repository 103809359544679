import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { colors } from "services/style";

export const mobileMediaQuery = "(max-width: 450px)";
export const desktopMediaQuery = "(min-width: 850px)";
export const useIsMobile = () => useMediaQuery(mobileMediaQuery);

const theme = createTheme({
  palette: {
    primary: {
      main: colors.secondary,
    },
    secondary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    info: {
      main: colors.tertiary,
    },
  },
  typography: {
    h1: {
      fontSize: "3rem",
      fontWeight: 800,
      lineHeight: 0.95,
    },
    h2: {
      fontSize: "2.7rem",
      fontWeight: 800,
      lineHeight: 1.1,
    },
    h3: {
      fontSize: "2.4rem",
      fontWeight: 700,
      lineHeight: 1.1,
    },
    h4: {
      fontSize: "2.1rem",
      fontWeight: 700,
      lineHeight: 1.1,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: "0.8rem",
      fontWeight: 400,
      lineHeight: 1.4,
    },
    caption: {
      fontSize: "0.62rem",
      lineHeight: 1.2,
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: colors.primary,
        },
        sizeSmall: {
          fontSize: "0.7rem",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          lineHeight: 1.1,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          color: colors.tertiary,
        },
        thumb: {
          backgroundColor: colors.tertiary,
        },
        markLabel: {
          fontSize: "0.7rem",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          display: "inline-block",
        },
        anchorOriginTopRightRectangular: {
          transform: "scale(1) translate(10%, -50%)",
        },
        anchorOriginTopLeftRectangular: {
          transform: "scale(1) translate(-10%, -50%)",
        },
        badge: {
          flexDirection: "column",
          height: "unset",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "0.3rem 0.75rem",
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          height: "100%",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          flexGrow: 1,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary,
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: "24px 16px",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          "&.Mui-active": {
            color: colors.white,
            opacity: 1,
          },
          color: colors.white,
          opacity: 0.6,
        },
        iconContainer: {
          color: colors.white,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          color: colors.white,
          [`@media ${desktopMediaQuery}`]: {
            padding: "0 20px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          backgroundColor: colors.secondary,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: "block",
          padding: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          lineHeight: 1.5,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary",
      },
    },
  },
});

export default theme;
