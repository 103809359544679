import { signOut } from "firebase/auth";
import { call, takeLeading } from "redux-saga/effects";

import { auth } from "modules/firebase/auth";

import * as actions from "./LoggedUser.actions";

function* logoutUser(action: ReturnType<typeof actions.logoutUser>) {
  yield call(() => signOut(auth));
}

export default function* userSaga() {
  yield takeLeading(actions.logoutUser, logoutUser);
}
