import { createAction } from "@reduxjs/toolkit";

export const submitAnimauxQuote = createAction<FormAnimauxValues>("CREATE_ANIMAUX_QUOTE_REQUEST");
export const submitAnimauxQuoteSuccess = createAction<WithId<AnimauxQuote>>("CREATE_ANIMAUX_QUOTE_SUCCESS");
export const submitAnimauxQuoteError = createAction<Error>("CREATE_ANIMAUX_QUOTE_ERROR");

export const fetchAnimauxQuote = createAction<string>("FETCH_ANIMAUX_QUOTE_REQUEST");
export const fetchAnimauxQuoteError = createAction<Error>("FETCH_ANIMAUX_QUOTE_ERROR");
export const fetchAnimauxQuotesSuccess = createAction<WithId<AnimauxQuote>[]>("FETCH_ANIMAUX_QUOTES_SUCCESS");

export const updateAnimauxQuote = createAction<WithId<AnimauxQuote>>("UPDATE_ANIMAUX_QUOTE_REQUEST");
export const updateAnimauxQuoteSuccess = createAction<WithId<AnimauxQuote>>("UPDATE_ANIMAUX_QUOTE_SUCCESS");
export const updateAnimauxQuoteError = createAction<Error>("UPDATE_ANIMAUX_QUOTE_ERROR");

export const saveAnimauxQuote = createAction("SAVE_ANIMAUX_QUOTE_REQUEST", (animauxQuoteId: string, tarif: Tarif) => ({
  payload: { animauxQuoteId, tarif },
}));
export const saveAnimauxQuoteSuccess = createAction("SAVE_ANIMAUX_QUOTE_SUCCESS");
export const saveAnimauxQuoteError = createAction<Error>("SAVE_ANIMAUX_QUOTE_ERROR");
