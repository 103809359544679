import { push } from "connected-react-router";
import { call, delay, put, takeEvery } from "redux-saga/effects";

import routes from "App.routes";
import { logEvent } from "modules/firebase/analytics";
import * as firestore from "modules/firebase/firestore";
import * as request from "services/networking";

import * as actions from "./HabitationQuote.actions";

function* submitHabitationQuote(action: ReturnType<typeof actions.submitHabitationQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<HabitationQuote> = yield call(
      request.post<FormHabitationValues>("habitation/tarifs", formValues),
    );
    logEvent("Demande_de_devis_succes");

    yield put(actions.submitHabitationQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formHabitation(quote.id)));
  } catch (error: any) {
    yield put(actions.submitHabitationQuoteError(error));
  }
}

function* fetchHabitationQuote(action: ReturnType<typeof actions.fetchHabitationQuote>) {
  try {
    const habitationQuoteId = action.payload;

    const quote: WithId<HabitationQuote> = yield call(firestore.get(`quotes/${habitationQuoteId}`));

    yield put(actions.fetchHabitationQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchHabitationQuoteError(error));
  }
}

function* updateHabitationQuote(action: ReturnType<typeof actions.updateHabitationQuote>) {
  try {
    const habitationQuote = action.payload;

    yield put(actions.updateHabitationQuoteSuccess(habitationQuote));
  } catch (error: any) {
    yield put(actions.updateHabitationQuoteError(error));
  }
}

function* saveHabitationQuote(action: ReturnType<typeof actions.saveHabitationQuote>) {
  try {
    const { habitationQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`habitation/devis/${habitationQuoteId}`, tarif));

    yield put(actions.saveHabitationQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveHabitationQuoteError(error));
  }
}

export default function* habitationQuoteSaga() {
  yield takeEvery(actions.submitHabitationQuote, submitHabitationQuote);
  yield takeEvery(actions.fetchHabitationQuote, fetchHabitationQuote);
  yield takeEvery(actions.updateHabitationQuote, updateHabitationQuote);
  yield takeEvery(actions.saveHabitationQuote, saveHabitationQuote);
}
