import { DefaultRootState, connect } from "react-redux";
import compose from "recompose/compose";

import { fetchPartenaires } from "modules/Partenaire/Partenaire.actions";
import { selectLogoUrls } from "modules/Partenaire/Partenaire.selectors";

import BandePartenaires, { OwnProps, Props } from "./BandePartenaires.component";

export const mapStateToProps = (state: DefaultRootState) => ({
  logoUrls: selectLogoUrls(state),
});

export const mapDispatchToProps = {
  fetchPartenaires,
};

export default compose<Props, OwnProps>(connect(mapStateToProps, mapDispatchToProps))(BandePartenaires);
