import { push } from "connected-react-router";
import { call, delay, put, takeEvery } from "redux-saga/effects";

import routes from "App.routes";
import { logEvent } from "modules/firebase/analytics";
import * as firestore from "modules/firebase/firestore";
import * as request from "services/networking";

import * as actions from "./AnimauxQuote.actions";

function* submitAnimauxQuote(action: ReturnType<typeof actions.submitAnimauxQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<AnimauxQuote> = yield call(request.post<FormAnimauxValues>("animaux/tarifs", formValues));
    logEvent("Demande_de_devis_succes");

    yield put(actions.submitAnimauxQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formAnimaux(quote.id)));
  } catch (error: any) {
    yield put(actions.submitAnimauxQuoteError(error));
  }
}

function* fetchAnimauxQuote(action: ReturnType<typeof actions.fetchAnimauxQuote>) {
  try {
    const animauxQuoteId = action.payload;

    const quote: WithId<AnimauxQuote> = yield call(firestore.get(`quotes/${animauxQuoteId}`));
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchAnimauxQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchAnimauxQuoteError(error));
  }
}

function* updateAnimauxQuote(action: ReturnType<typeof actions.updateAnimauxQuote>) {
  try {
    const animauxQuote = action.payload;

    yield put(actions.updateAnimauxQuoteSuccess(animauxQuote));
  } catch (error: any) {
    yield put(actions.updateAnimauxQuoteError(error));
  }
}

function* saveAnimauxQuote(action: ReturnType<typeof actions.saveAnimauxQuote>) {
  try {
    const { animauxQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`animaux/devis/${animauxQuoteId}`, tarif));

    yield put(actions.saveAnimauxQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveAnimauxQuoteError(error));
  }
}

export default function* animauxQuoteSaga() {
  yield takeEvery(actions.submitAnimauxQuote, submitAnimauxQuote);
  yield takeEvery(actions.fetchAnimauxQuote, fetchAnimauxQuote);
  yield takeEvery(actions.updateAnimauxQuote, updateAnimauxQuote);
  yield takeEvery(actions.saveAnimauxQuote, saveAnimauxQuote);
}
