import { createAction } from "@reduxjs/toolkit";

export const submitAutoTemporaireQuote = createAction<FormAutoTemporaireValues>("CREATE_AUTO_TEMPORAIRE_QUOTE_REQUEST");
export const submitAutoTemporaireQuoteSuccess = createAction<WithId<AutoTemporaireQuote>>(
  "CREATE_AUTO_TEMPORAIRE_QUOTE_SUCCESS",
);
export const submitAutoTemporaireQuoteError = createAction<Error>("CREATE_AUTO_TEMPORAIRE_QUOTE_ERROR");

export const fetchAutoTemporaireQuote = createAction<string>("FETCH_AUTO_TEMPORAIRE_QUOTE_REQUEST");
export const fetchAutoTemporaireQuoteError = createAction<Error>("FETCH_AUTO_TEMPORAIRE_QUOTE_ERROR");
export const fetchAutoTemporaireQuotesSuccess = createAction<WithId<AutoTemporaireQuote>[]>(
  "FETCH_AUTO_TEMPORAIRE_QUOTES_SUCCESS",
);

export const updateAutoTemporaireQuote = createAction<WithId<AutoTemporaireQuote>>(
  "UPDATE_AUTO_TEMPORAIRE_QUOTE_REQUEST",
);
export const updateAutoTemporaireQuoteSuccess = createAction<WithId<AutoTemporaireQuote>>(
  "UPDATE_AUTO_TEMPORAIRE_QUOTE_SUCCESS",
);
export const updateAutoTemporaireQuoteError = createAction<Error>("UPDATE_AUTO_TEMPORAIRE_QUOTE_ERROR");

export const saveAutoTemporaireQuote = createAction(
  "SAVE_AUTO_TEMPORAIRE_QUOTE_REQUEST",
  (autoTemporaireQuoteId: string, tarif: Tarif) => ({
    payload: { autoTemporaireQuoteId, tarif },
  }),
);
export const saveAutoTemporaireQuoteSuccess = createAction("SAVE_AUTO_TEMPORAIRE_QUOTE_SUCCESS");
export const saveAutoTemporaireQuoteError = createAction<Error>("SAVE_AUTO_TEMPORAIRE_QUOTE_ERROR");
