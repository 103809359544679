import axios, { AxiosResponse } from "axios";

import { isLocalhost } from "services/navigation";

export const baseUrl = isLocalhost ? `http://localhost:5080` : "https://back.assurecompare.fr";
export const baseApiUrl = `${baseUrl}/api/`;

const getUrl = (uri: string) => (uri.includes("://") ? uri : baseApiUrl + uri);

export const get = <Response extends any>(endpoint: string) => () =>
  axios.get<any, AxiosResponse<Response>>(getUrl(endpoint)).then((response) => response.data);

export const post = <Data extends any, Response extends any = {}>(endpoint: string, data: Data) => () =>
  axios.post<Data, AxiosResponse<Response>>(getUrl(endpoint), data).then((response) => response.data);

export const put = <Data extends any, Response extends any = {}>(endpoint: string, data: Data) => () =>
  axios.put<Data, AxiosResponse<Response>>(getUrl(endpoint), data).then((response) => response.data);
