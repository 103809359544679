import { createAction } from "@reduxjs/toolkit";

export const submitHabitationQuote = createAction<FormHabitationValues>("CREATE_HABITATION_QUOTE_REQUEST");
export const submitHabitationQuoteSuccess = createAction<WithId<HabitationQuote>>("CREATE_HABITATION_QUOTE_SUCCESS");
export const submitHabitationQuoteError = createAction<Error>("CREATE_HABITATION_QUOTE_ERROR");

export const fetchHabitationQuote = createAction<string>("FETCH_HABITATION_QUOTE_REQUEST");
export const fetchHabitationQuoteError = createAction<Error>("FETCH_HABITATION_QUOTE_ERROR");
export const fetchHabitationQuotesSuccess = createAction<WithId<HabitationQuote>[]>("FETCH_HABITATION_QUOTES_SUCCESS");

export const updateHabitationQuote = createAction<WithId<HabitationQuote>>("UPDATE_HABITATION_QUOTE_REQUEST");
export const updateHabitationQuoteSuccess = createAction<WithId<HabitationQuote>>("UPDATE_HABITATION_QUOTE_SUCCESS");
export const updateHabitationQuoteError = createAction<Error>("UPDATE_HABITATION_QUOTE_ERROR");

export const saveHabitationQuote = createAction(
  "SAVE_HABITATION_QUOTE_REQUEST",
  (habitationQuoteId: string, tarif: Tarif) => ({ payload: { habitationQuoteId, tarif } }),
);
export const saveHabitationQuoteSuccess = createAction("SAVE_HABITATION_QUOTE_SUCCESS");
export const saveHabitationQuoteError = createAction<Error>("SAVE_HABITATION_QUOTE_ERROR");
