import { createAction } from "@reduxjs/toolkit";

export const submitMobileQuote = createAction<FormMobileValues>("CREATE_MOBILE_QUOTE_REQUEST");
export const submitMobileQuoteSuccess = createAction<WithId<MobileQuote>>("CREATE_MOBILE_QUOTE_SUCCESS");
export const submitMobileQuoteError = createAction<Error>("CREATE_MOBILE_QUOTE_ERROR");

export const fetchMobileQuote = createAction<string>("FETCH_MOBILE_QUOTE_REQUEST");
export const fetchMobileQuoteError = createAction<Error>("FETCH_MOBILE_QUOTE_ERROR");
export const fetchMobileQuotesSuccess = createAction<WithId<MobileQuote>[]>("FETCH_MOBILE_QUOTES_SUCCESS");

export const updateMobileQuote = createAction<WithId<MobileQuote>>("UPDATE_MOBILE_QUOTE_REQUEST");
export const updateMobileQuoteSuccess = createAction<WithId<MobileQuote>>("UPDATE_MOBILE_QUOTE_SUCCESS");
export const updateMobileQuoteError = createAction<Error>("UPDATE_MOBILE_QUOTE_ERROR");

export const saveMobileQuote = createAction("SAVE_MOBILE_QUOTE_REQUEST", (mobileQuoteId: string, tarif: Tarif) => ({
  payload: { mobileQuoteId, tarif },
}));
export const saveMobileQuoteSuccess = createAction("SAVE_MOBILE_QUOTE_SUCCESS");
export const saveMobileQuoteError = createAction<Error>("SAVE_MOBILE_QUOTE_ERROR");
