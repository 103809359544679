import styled from "@emotion/styled";
import React from "react";

import { colors } from "services/style";

export interface ColoredContainerProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  color?: keyof typeof colors;
  textColor?: keyof typeof colors;
}

export const StyledColoredContainer = styled(({ color, textColor, ...props }: ColoredContainerProps) => (
  <div {...props} />
))`
  background-color: ${({ color = "primary" }: ColoredContainerProps) => colors[color]};
  color: ${({ textColor = "white" }: ColoredContainerProps) => colors[textColor]};
`;
