import { push } from "connected-react-router";
import { call, delay, put, takeEvery } from "redux-saga/effects";

import routes from "App.routes";
import { logEvent } from "modules/firebase/analytics";
import * as firestore from "modules/firebase/firestore";
import * as request from "services/networking";

import * as actions from "./MotoQuote.actions";

function* submitMotoQuote(action: ReturnType<typeof actions.submitMotoQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<MotoQuote> = yield call(request.post<FormMotoValues>("moto/tarifs", formValues));
    logEvent("Demande_de_devis_succes");

    yield put(actions.submitMotoQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formMoto(quote.id)));
  } catch (error: any) {
    yield put(actions.submitMotoQuoteError(error));
  }
}

function* fetchMotoQuote(action: ReturnType<typeof actions.fetchMotoQuote>) {
  try {
    const motoQuoteId = action.payload;

    const quote: WithId<MotoQuote> = yield call(firestore.get(`quotes/${motoQuoteId}`));

    yield put(actions.fetchMotoQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchMotoQuoteError(error));
  }
}

function* updateMotoQuote(action: ReturnType<typeof actions.updateMotoQuote>) {
  try {
    const motoQuote = action.payload;

    yield put(actions.updateMotoQuoteSuccess(motoQuote));
  } catch (error: any) {
    yield put(actions.updateMotoQuoteError(error));
  }
}

function* saveMotoQuote(action: ReturnType<typeof actions.saveMotoQuote>) {
  try {
    const { motoQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`moto/devis/${motoQuoteId}`, tarif));

    yield put(actions.saveMotoQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveMotoQuoteError(error));
  }
}

export default function* motoQuoteSaga() {
  yield takeEvery(actions.submitMotoQuote, submitMotoQuote);
  yield takeEvery(actions.fetchMotoQuote, fetchMotoQuote);
  yield takeEvery(actions.updateMotoQuote, updateMotoQuote);
  yield takeEvery(actions.saveMotoQuote, saveMotoQuote);
}
