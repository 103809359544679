// @ts-ignore
import Carousel from "@brainhubeu/react-carousel";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

import { colors } from "services/style";

export const StyledContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  border-top: 5px solid ${colors.tertiary};
  margin-top: 20px;
`;

export const StyledCarousel = styled(Carousel)`
  margin-bottom: 15px;
`;

export const StyledTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2em;
  padding: 15px 0 10px;
`;
