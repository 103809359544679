import React from "react";
import { Route, Switch } from "react-router";

import routes from "App.routes";
import Contact from "pages/Contact";
import Home from "pages/Home";
import { useAuth } from "services/firebase/auth";

import { StyledContainer } from "./AppLayout.style";
import BandePartenaires from "./components/BandePartenaires";
import Footer from "./components/Footer";
import Header from "./components/Header";

const MentionsLegales = React.lazy(() => import(/* webpackChunkName: "MentionsLegales" */ "pages/MentionsLegales"));
const Partenaires = React.lazy(() => import(/* webpackChunkName: "Partenaires" */ "pages/Partenaires"));
const CGU = React.lazy(() => import(/* webpackChunkName: "CGU" */ "pages/CGU"));
const Login = React.lazy(() => import(/* webpackChunkName: "Login" */ "pages/Login"));
const Admin = React.lazy(() => import(/* webpackChunkName: "Admin" */ "pages/Admin"));
const AutoMoto = React.lazy(() => import(/* webpackChunkName: "AutoMoto" */ "pages/AutoMoto"));
const AutresAssurances = React.lazy(() => import(/* webpackChunkName: "AutresAssurances" */ "pages/AutresAssurances"));
const FormSante = React.lazy(() => import(/* webpackChunkName: "FormSante" */ "pages/FormSante"));
const FormEmprunteur = React.lazy(() => import(/* webpackChunkName: "FormEmprunteur" */ "pages/FormEmprunteur"));
const FormHabitation = React.lazy(() => import(/* webpackChunkName: "FormHabitation" */ "pages/FormHabitation"));
const FormAnimaux = React.lazy(() => import(/* webpackChunkName: "FormAnimaux" */ "pages/FormAnimaux"));
const FormAuto = React.lazy(() => import(/* webpackChunkName: "FormAuto" */ "pages/FormAuto"));
const FormMoto = React.lazy(() => import(/* webpackChunkName: "FormMoto" */ "pages/FormMoto"));
const FormVoyage = React.lazy(() => import(/* webpackChunkName: "FormVoyage" */ "pages/FormVoyage"));
const FormSansPermis = React.lazy(() => import(/* webpackChunkName: "FormSansPermis" */ "pages/FormSansPermis"));
const FormCampingCar = React.lazy(() => import(/* webpackChunkName: "FormCampingCar" */ "pages/FormCampingCar"));
const FormMobile = React.lazy(() => import(/* webpackChunkName: "FormMobile" */ "pages/FormMobile"));
const FormLoyerImpaye = React.lazy(() => import(/* webpackChunkName: "FormLoyerImpaye" */ "pages/FormLoyerImpaye"));
const FormAutoTemporaire = React.lazy(
  () => import(/* webpackChunkName: "FormAutoTemporaire" */ "pages/FormAutoTemporaire"),
);
const FormTelesurveillance = React.lazy(
  () => import(/* webpackChunkName: "FormTelesurveillance" */ "pages/FormTelesurveillance"),
);
const FormSurcomplementaire = React.lazy(
  () => import(/* webpackChunkName: "FormSurcomplementaire" */ "pages/FormSurcomplementaire"),
);
const FormRCPro = React.lazy(() => import(/* webpackChunkName: "FormRCPro" */ "pages/FormRCPro"));
const EspaceClient = React.lazy(() => import(/* webpackChunkName: "EspaceClient" */ "pages/EspaceClient"));

const AppLayout = () => {
  useAuth();

  return (
    <StyledContainer>
      <React.Suspense fallback={null}>
        <Header />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <Switch>
          <Route path={routes.formSante()} component={FormSante} />
          <Route path={routes.formEmprunteur()} component={FormEmprunteur} />
          <Route path={routes.formHabitation()} component={FormHabitation} />
          <Route path={routes.formAnimaux()} component={FormAnimaux} />
          <Route path={routes.autoMoto()} component={AutoMoto} />
          <Route path={routes.formAuto()} component={FormAuto} />
          <Route path={routes.formMoto()} component={FormMoto} />
          <Route path={routes.formVoyage()} component={FormVoyage} />
          <Route path={routes.formMobile()} component={FormMobile} />
          <Route path={routes.formSansPermis()} component={FormSansPermis} />
          <Route path={routes.formCampingCar()} component={FormCampingCar} />
          <Route path={routes.formLoyerImpaye()} component={FormLoyerImpaye} />
          <Route path={routes.formAutoTemporaire()} component={FormAutoTemporaire} />
          <Route path={routes.formTelesurveillance()} component={FormTelesurveillance} />
          <Route path={routes.formSurcomplementaire()} component={FormSurcomplementaire} />
          <Route path={routes.formRCPro()} component={FormRCPro} />
          <Route path={routes.autres()} component={AutresAssurances} />
          <Route path={routes.admin()} component={Admin} />
          <Route path={routes.login()} component={Login} />
          <Route path={routes.cgu()} component={CGU} />
          <Route path={routes.mentionsLegales()} component={MentionsLegales} />
          <Route path={routes.espaceClient()} component={EspaceClient} />
          <Route path={routes.contact()} component={Contact} />
          <Route path={routes.partenaires()} component={Partenaires} />
          <Route path={routes.home()} component={Home} />
        </Switch>
      </React.Suspense>
      <React.Suspense fallback={null}>
        <BandePartenaires />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <Footer />
      </React.Suspense>
    </StyledContainer>
  );
};

export default AppLayout;
