import { createAction } from "@reduxjs/toolkit";

export const submitLoyerImpayeQuote = createAction<FormLoyerImpayeValues>("CREATE_LOYER_IMPAYE_QUOTE_REQUEST");
export const submitLoyerImpayeQuoteSuccess = createAction<WithId<LoyerImpayeQuote>>(
  "CREATE_LOYER_IMPAYE_QUOTE_SUCCESS",
);
export const submitLoyerImpayeQuoteError = createAction<Error>("CREATE_LOYER_IMPAYE_QUOTE_ERROR");

export const fetchLoyerImpayeQuote = createAction<string>("FETCH_LOYER_IMPAYE_QUOTE_REQUEST");
export const fetchLoyerImpayeQuoteError = createAction<Error>("FETCH_LOYER_IMPAYE_QUOTE_ERROR");
export const fetchLoyerImpayeQuotesSuccess = createAction<WithId<LoyerImpayeQuote>[]>(
  "FETCH_LOYER_IMPAYE_QUOTES_SUCCESS",
);

export const updateLoyerImpayeQuote = createAction<WithId<LoyerImpayeQuote>>("UPDATE_LOYER_IMPAYE_QUOTE_REQUEST");
export const updateLoyerImpayeQuoteSuccess = createAction<WithId<LoyerImpayeQuote>>(
  "UPDATE_LOYER_IMPAYE_QUOTE_SUCCESS",
);
export const updateLoyerImpayeQuoteError = createAction<Error>("UPDATE_LOYER_IMPAYE_QUOTE_ERROR");

export const saveLoyerImpayeQuote = createAction(
  "SAVE_LOYER_IMPAYE_QUOTE_REQUEST",
  (loyerImpayeQuoteId: string, tarif: Tarif) => ({ payload: { loyerImpayeQuoteId, tarif } }),
);
export const saveLoyerImpayeQuoteSuccess = createAction("SAVE_LOYER_IMPAYE_QUOTE_SUCCESS");
export const saveLoyerImpayeQuoteError = createAction<Error>("SAVE_LOYER_IMPAYE_QUOTE_ERROR");
