import { push } from "connected-react-router";
import { call, delay, put, takeEvery } from "redux-saga/effects";

import routes from "App.routes";
import { logEvent } from "modules/firebase/analytics";
import * as firestore from "modules/firebase/firestore";
import * as request from "services/networking";

import * as actions from "./RCProQuote.actions";

function* submitRCProQuote(action: ReturnType<typeof actions.submitRCProQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<RCProQuote> = yield call(request.post<FormRCProValues>("rcPro/tarifs", formValues));
    logEvent("Demande_de_devis_succes");

    yield put(actions.submitRCProQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formRCPro(quote.id)));
  } catch (error: any) {
    yield put(actions.submitRCProQuoteError(error));
  }
}

function* fetchRCProQuote(action: ReturnType<typeof actions.fetchRCProQuote>) {
  try {
    const rcProQuoteId = action.payload;

    const quote: WithId<RCProQuote> = yield call(firestore.get(`quotes/${rcProQuoteId}`));
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchRCProQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchRCProQuoteError(error));
  }
}

function* updateRCProQuote(action: ReturnType<typeof actions.updateRCProQuote>) {
  try {
    const rcProQuote = action.payload;

    yield put(actions.updateRCProQuoteSuccess(rcProQuote));
  } catch (error: any) {
    yield put(actions.updateRCProQuoteError(error));
  }
}

function* saveRCProQuote(action: ReturnType<typeof actions.saveRCProQuote>) {
  try {
    const { rcProQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`rcPro/devis/${rcProQuoteId}`, tarif));

    yield put(actions.saveRCProQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveRCProQuoteError(error));
  }
}

export default function* rcProQuoteSaga() {
  yield takeEvery(actions.submitRCProQuote, submitRCProQuote);
  yield takeEvery(actions.fetchRCProQuote, fetchRCProQuote);
  yield takeEvery(actions.updateRCProQuote, updateRCProQuote);
  yield takeEvery(actions.saveRCProQuote, saveRCProQuote);
}
