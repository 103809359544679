import styled from "@emotion/styled";

import { colors } from "services/style";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: ${colors.lighterGrey};

  /*&:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('/img/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -2;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: -1;
  }*/
`;
