import { push } from "connected-react-router";
import { call, delay, put, takeEvery } from "redux-saga/effects";

import routes from "App.routes";
import { logEvent } from "modules/firebase/analytics";
import * as firestore from "modules/firebase/firestore";
import * as request from "services/networking";

import * as actions from "./TelesurveillanceQuote.actions";

function* submitTelesurveillanceQuote(action: ReturnType<typeof actions.submitTelesurveillanceQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<TelesurveillanceQuote> = yield call(
      request.post<FormTelesurveillanceValues>("telesurveillance/tarifs", formValues),
    );
    logEvent("Demande_de_devis_succes");

    yield put(actions.submitTelesurveillanceQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formTelesurveillance(quote.id)));
  } catch (error: any) {
    yield put(actions.submitTelesurveillanceQuoteError(error));
  }
}

function* fetchTelesurveillanceQuote(action: ReturnType<typeof actions.fetchTelesurveillanceQuote>) {
  try {
    const telesurveillanceQuoteId = action.payload;

    const quote: WithId<TelesurveillanceQuote> = yield call(firestore.get(`quotes/${telesurveillanceQuoteId}`));
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchTelesurveillanceQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchTelesurveillanceQuoteError(error));
  }
}

function* updateTelesurveillanceQuote(action: ReturnType<typeof actions.updateTelesurveillanceQuote>) {
  try {
    const telesurveillanceQuote = action.payload;

    yield put(actions.updateTelesurveillanceQuoteSuccess(telesurveillanceQuote));
  } catch (error: any) {
    yield put(actions.updateTelesurveillanceQuoteError(error));
  }
}

function* saveTelesurveillanceQuote(action: ReturnType<typeof actions.saveTelesurveillanceQuote>) {
  try {
    const { telesurveillanceQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`telesurveillance/devis/${telesurveillanceQuoteId}`, tarif));

    yield put(actions.saveTelesurveillanceQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveTelesurveillanceQuoteError(error));
  }
}

export default function* telesurveillanceQuoteSaga() {
  yield takeEvery(actions.submitTelesurveillanceQuote, submitTelesurveillanceQuote);
  yield takeEvery(actions.fetchTelesurveillanceQuote, fetchTelesurveillanceQuote);
  yield takeEvery(actions.updateTelesurveillanceQuote, updateTelesurveillanceQuote);
  yield takeEvery(actions.saveTelesurveillanceQuote, saveTelesurveillanceQuote);
}
