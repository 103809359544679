import MenuItem from "@mui/material/MenuItem";
import React from "react";

import InputField, { InputFieldProps } from "components/InputField";

export interface OwnProps extends Omit<InputFieldProps, "type" | "label" | "mask" | "rules"> {}

export interface Props extends OwnProps {}

const CiviliteField = (props: Props) => (
  <InputField {...props} style={{ minWidth: "100px", flexGrow: 0 }} label="Civilité" rules={{ required: true }} select>
    <MenuItem value="H">M</MenuItem>
    <MenuItem value="F">Mme</MenuItem>
  </InputField>
);

export default CiviliteField;
