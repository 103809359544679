import { createAction } from "@reduxjs/toolkit";

export const submitSansPermisQuote = createAction<FormSansPermisValues>("CREATE_SANS_PERMIS_QUOTE_REQUEST");
export const submitSansPermisQuoteSuccess = createAction<WithId<SansPermisQuote>>("CREATE_SANS_PERMIS_QUOTE_SUCCESS");
export const submitSansPermisQuoteError = createAction<Error>("CREATE_SANS_PERMIS_QUOTE_ERROR");

export const fetchSansPermisQuote = createAction<string>("FETCH_SANS_PERMIS_QUOTE_REQUEST");
export const fetchSansPermisQuoteError = createAction<Error>("FETCH_SANS_PERMIS_QUOTE_ERROR");
export const fetchSansPermisQuotesSuccess = createAction<WithId<SansPermisQuote>[]>("FETCH_SANS_PERMIS_QUOTES_SUCCESS");

export const updateSansPermisQuote = createAction<WithId<SansPermisQuote>>("UPDATE_SANS_PERMIS_QUOTE_REQUEST");
export const updateSansPermisQuoteSuccess = createAction<WithId<SansPermisQuote>>("UPDATE_SANS_PERMIS_QUOTE_SUCCESS");
export const updateSansPermisQuoteError = createAction<Error>("UPDATE_SANS_PERMIS_QUOTE_ERROR");

export const saveSansPermisQuote = createAction(
  "SAVE_SANS_PERMIS_QUOTE_REQUEST",
  (sansPermisQuoteId: string, tarif: Tarif) => ({ payload: { sansPermisQuoteId, tarif } }),
);
export const saveSansPermisQuoteSuccess = createAction("SAVE_SANS_PERMIS_QUOTE_SUCCESS");
export const saveSansPermisQuoteError = createAction<Error>("SAVE_SANS_PERMIS_QUOTE_ERROR");
