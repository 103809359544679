import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { DefaultRootState } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import createRootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["router", "snackbar"],
};

export const history = createBrowserHistory();

export const store = configureStore({
  middleware: [sagaMiddleware, routerMiddleware(history)],
  reducer: persistReducer<DefaultRootState>(persistConfig, createRootReducer(history)),
});
export const persistor = persistStore(store);

// after mounting the saga middleware in the store
sagaMiddleware.run(rootSaga);
