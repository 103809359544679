import { User, onAuthStateChanged } from "firebase/auth";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as loginActions from "modules/LoggedUser/LoggedUser.actions";
import { selectLoggedUserEmail } from "modules/LoggedUser/LoggedUser.selectors";
import { auth } from "modules/firebase/auth";

export const useAuth = () => {
  const dispatch = useDispatch();
  const loggedUserEmail = useSelector(selectLoggedUserEmail);
  const isLogged = !!loggedUserEmail;

  // handle Firebase Auth state
  React.useEffect(() => {
    onAuthStateChanged(auth, (user: User | null) => {
      if (!!user && !isLogged) dispatch(loginActions.loginUser(user));
      else if (!user && isLogged) dispatch(loginActions.logoutUser());
    });
  }, [dispatch, isLogged]);

  return { isLogged, loggedUserEmail };
};
