import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import ColoredContainer from "components/ColoredContainer";
import { colors } from "services/style";
import { mobileMediaQuery } from "services/theme";

export const StyledColoredFooter = styled(ColoredContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;

  @media ${mobileMediaQuery} {
    flex-direction: column;
  }
`;

export const StyledColoredContainer = styled(ColoredContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-weight: 500;
  font-size: 1.2em;
  text-transform: uppercase;

  @media ${mobileMediaQuery} {
    flex-direction: column;
  }
`;

export const StyledFlex = styled.div`
  && > * {
    padding: 10px 10px;
  }

  @media ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
    align-items: center;

    && > * {
      padding-bottom: 8px;
    }
  }
`;

export const StyledListHeader = styled(Typography)`
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.4em;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 80px;
    border-bottom: 3px solid ${colors.tertiary};
  }
`;

export const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${colors.lightGrey};
  max-height: 190px;
  font-size: 1.2em;

  @media ${mobileMediaQuery} {
    max-height: none;
  }
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  text-align: center;
  padding: 0 10px;

  &:hover {
    color: ${colors.white};
  }
`;

export const StyledLogo = styled.img`
  filter: brightness(0) invert(0.8);

  @media ${mobileMediaQuery} {
    padding-top: 30px;
  }
`;
