import { call, put, takeLatest } from "redux-saga/effects";

import { getAllUrls } from "modules/firebase/storage";

import * as actions from "./Partenaire.actions";

function* fetchPartenaires(action: ReturnType<typeof actions.fetchPartenaires>) {
  try {
    const logoUrls: string[] = yield call(getAllUrls("partenaires"));

    yield put(actions.fetchPartenairesSuccess(logoUrls));
  } catch (error: any) {
    yield put(actions.fetchPartenairesError(error));
  }
}

export default function* partenaireSaga() {
  yield takeLatest(actions.fetchPartenaires, fetchPartenaires);
}
