import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Action } from "@reduxjs/toolkit";
import _omit from "lodash/omit";
import React from "react";
import isEqual from "react-fast-compare";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { useDeepEffect } from "services/hooks";

export const fetch = <P extends object, Params extends { [key in keyof Params]: string } = {}>(
  fetchOptions: (
    state: DefaultRootState,
    params: Params,
    props: P,
  ) => {
    actions: Action<any>[];
    isFetching: boolean;
    isLoading?: boolean;
  },
) => (Element: React.ComponentType<P>) => (props: P) => {
  const params = useParams<Params>();
  const dispatch = useDispatch();
  const rootState = useSelector((state) => state);

  const { actions, isFetching, isLoading = false } = fetchOptions(rootState, params, props);
  actions.forEach((action) => {
    useDeepEffect(() => {
      dispatch(action);
    }, [dispatch, action]);
  });

  if (isLoading)
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );

  return <Element {...props} isFetching={isFetching} />;
};

export const withoutProps = <P extends object, T extends P>(omittedProps: string[]) => (
  Element: React.ComponentType<P>,
) => (props: T) => <Element {...(_omit(props, omittedProps) as P)} />;

export const deepMemo = <P extends object>(Element: React.ComponentType<P>) => React.memo(Element, isEqual);
