import "@brainhubeu/react-carousel/lib/style.css";
import React from "react";

import { useIsMobile } from "services/theme";

import { mapDispatchToProps, mapStateToProps } from "./BandePartenaires.container";
import { StyledCarousel, StyledContainer, StyledTitle } from "./BandePartenaires.style";

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export interface OwnProps {}

export interface Props extends StateProps, DispatchProps, OwnProps {}

const BandePartenaires = ({ fetchPartenaires, logoUrls }: Props) => {
  const isMobile = useIsMobile();

  React.useEffect(() => {
    fetchPartenaires();
  }, [fetchPartenaires]);

  const nbSlides = isMobile ? 2 : 8;

  return (
    <StyledContainer>
      <StyledTitle variant="h4">Nos partenaires</StyledTitle>
      <StyledCarousel
        autoPlay={nbSlides * 1000}
        slidesPerPage={nbSlides}
        slidesPerScroll={nbSlides}
        animationSpeed={500}
        stopAutoPlayOnHover
        clickToChange
        infinite
      >
        {logoUrls.map((logoUrl) => (
          <img key={logoUrl} src={logoUrl} alt="Logo" loading="lazy" style={{ maxHeight: 80, maxWidth: 180 }} />
        ))}
      </StyledCarousel>
    </StyledContainer>
  );
};

export default BandePartenaires;
