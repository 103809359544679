import React from "react";

import { useForm } from "services/hooks";
import { baseUrl, post } from "services/networking";

export const useContactForm = () => {
  const [recaptchaToken, setRecaptchaToken] = React.useState("");
  const [recaptchaError, setRecaptchaError] = React.useState(false);
  const form = useForm({
    defaultValues: {
      civilite: "",
      nom: "",
      prenom: "",
      email: "",
      tel: "",
      message: "",
    },
  });

  const [sent, setSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = form.handleSubmit(async (values) => {
    if (!recaptchaToken) console.log("no recaptcha token");

    setLoading(true);

    await post(baseUrl + "/contact", values)();

    setLoading(false);
    setSent(true);
  });

  return { ...form, sent, loading, recaptchaError, setRecaptchaToken, setRecaptchaError, onSubmit };
};
