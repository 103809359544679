import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router";

export const isLocalhost = ["localhost", "127.0.0.1"].includes(window.location.hostname);
export const derUrl =
  "https://firebasestorage.googleapis.com/v0/b/assurecompare.appspot.com/o/DER-UNPLUSUN-11.24.pdf?alt=media&token=09e8f24e-27cc-4607-b5b5-f4de1992b5e6";

export const navigateDer = () => {
  window.open(derUrl, "_blank")?.blur();
  window.focus();
};

export const useNavigate = (
  pathname: string,
  {
    scrollTop = true,
    search = "",
  }: {
    scrollTop?: boolean;
    search?: string;
  } = {},
) => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch(push(pathname + search));
    if (!!scrollTop) window.scroll({ top: 0 });
  }, [dispatch, pathname, search, scrollTop]);
};

export const handleEnterSubmit = (
  handleSubmit: (event: React.KeyboardEvent<HTMLDivElement>) => any,
  needCtrlKey?: boolean,
) => (event: React.KeyboardEvent<HTMLDivElement>) => {
  if (event?.key === "Enter" && (!needCtrlKey || !!event?.metaKey || !!event?.ctrlKey)) {
    handleSubmit(event);
  }
};

export const useMatch = (route: { getUrl: (...params: string[]) => string }) => {
  const match = useRouteMatch(route.getUrl());

  return match?.isExact;
};
