/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React from "react";
import isEqual from "react-fast-compare";
import * as ReactHookForm from "react-hook-form";
import { DefaultRootState, useSelector } from "react-redux";
import { useLocation } from "react-router";

const shouldUpdate = (depsRef: React.MutableRefObject<React.DependencyList>, deps: React.DependencyList) => {
  if (depsRef.current !== deps && !isEqual(depsRef.current, deps)) {
    depsRef.current = deps;
  }

  return depsRef.current;
};

export const useDeepEffect = (effect: React.EffectCallback, deps: React.DependencyList) => {
  const depsRef = React.useRef(deps);

  React.useEffect(effect, [shouldUpdate(depsRef, deps)]);
};

export const useDeepCallback = (effect: React.EffectCallback, deps: React.DependencyList) => {
  const depsRef = React.useRef(deps);

  return React.useCallback(effect, [shouldUpdate(depsRef, deps)]);
};

export const useDeepMemo = <T>(factory: () => T, deps: React.DependencyList): T => {
  const depsRef = React.useRef(deps);

  return React.useMemo(factory, [shouldUpdate(depsRef, deps)]);
};

export const useDeepSelector = <T>(selector: (state: DefaultRootState) => T) => useSelector(selector, isEqual);

export const useToggle = (defaultFlag: boolean): [boolean, () => void, () => void, () => void] => {
  const [flag, setFlag] = React.useState(defaultFlag);

  return [flag, () => setFlag(true), () => setFlag(false), () => setFlag(!flag)];
};

export const createContext = <T>(defaultValue?: T) => React.createContext<T | null>(defaultValue || null);

export const useQueryParams = () => {
  const location = useLocation();

  return new URLSearchParams(location.search);
};

export const useForm = ({
  defaultValues,
  ...formOptions
}: {
  [key: string]: any;
  defaultValues: { [key: string]: any };
}) => {
  const { unregister, clearErrors, ...form } = ReactHookForm.useForm({
    shouldUnregister: false, // needed to keep state of form through pages
    defaultValues: defaultValues as any,
    ...formOptions,
  });

  const listeners: { [inputName: string]: (value: any) => any } = {};
  const useListen = (inputName: string, callback: (newValue: any) => void) => {
    listeners[inputName] = callback;
  };

  const transforms: { [inputName: string]: (value: any) => any } = {};
  const useTransform = (inputName: string, callback: (newValue: any) => any) => {
    transforms[inputName] = callback;
  };

  const resetFields = React.useCallback(
    (fields: string | string[]) => {
      unregister(fields, { keepDefaultValue: true });
      clearErrors(fields);
    },
    [unregister, clearErrors],
  );

  const { reset } = form;
  useDeepEffect(() => reset(defaultValues), [defaultValues, reset]);

  return {
    unregister,
    clearErrors,
    ...form,
    resetFields,
    useListen,
    useTransform,
    transforms,
    listeners,
  };
};

export type Form<T extends any = {}> = ReturnType<typeof useForm> &
  T & {
    onSubmit: (event?: React.KeyboardEvent<HTMLDivElement>) => void;
    disabled?: boolean;
  };
export const FormContext = createContext<Form>();
export const useFormContext = <T extends any = {}>() => React.useContext(FormContext) as Form<T> | null;

type FormValues<T> = {
  [K in keyof T]: T[K] extends number | boolean | Date | moment.Moment
    ? T[K]
    : T[K] extends string
    ? any
    : FormValues<T[K]>;
};

export const usePreviousStep = (activeStep: number, setActiveStep: (newActiveStep: number) => void) =>
  React.useCallback(() => {
    window.scrollTo({ top: 130, behavior: "smooth" });
    setActiveStep(Math.max(activeStep - 1, 0));
  }, [activeStep, setActiveStep]);
export const useNextStep = (activeStep: number, setActiveStep: (newActiveStep: number) => void, nbSteps = 2) =>
  React.useCallback(() => {
    window.scrollTo({ top: 130, behavior: "smooth" });
    setActiveStep(Math.min(activeStep + 1, nbSteps - 1));
  }, [activeStep, nbSteps, setActiveStep]);

export const useStepForm = <S1, S2>(
  defaultValues1: S1,
  defaultValues2: S2,
  onValid: (data1: FormValues<S1>, data2: FormValues<S2>) => void,
  disabled?: boolean,
) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const previousStep = usePreviousStep(activeStep, setActiveStep);
  const nextStep = useNextStep(activeStep, setActiveStep);

  const step1 = useForm({ defaultValues: defaultValues1 });
  const step2 = useForm({ defaultValues: defaultValues2 });

  const { reset: reset1 } = step1;
  useDeepEffect(() => {
    reset1(defaultValues1);
  }, [defaultValues1, reset1]);

  const { reset: reset2 } = step2;
  useDeepEffect(() => {
    reset2(defaultValues2);
  }, [defaultValues2, reset2]);

  const onSubmit1 = step1.handleSubmit(nextStep, previousStep);
  const onSubmit2 = React.useCallback(() => {
    step1.handleSubmit((data1) => {
      step2.handleSubmit((data2) => onValid(data1 as FormValues<S1>, data2 as FormValues<S2>), nextStep)();
    }, previousStep)();
  }, []);

  return {
    activeStep,
    setActiveStep,
    previousStep,
    nextStep,
    step1: { ...step1, onSubmit: onSubmit1, disabled },
    step2: { ...step2, onSubmit: onSubmit2, disabled },
  };
};

export const useTimeout = (handler: Parameters<typeof window.setTimeout>[0], timeout?: number) =>
  React.useEffect(() => {
    const timeoutId = window.setTimeout(handler, timeout);

    return () => window.clearTimeout(timeoutId);
  }, [handler, timeout]);
