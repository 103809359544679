export const testEmail = (data: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    data,
  );

export const testCodePostal = (data: string) => /^[0-9]{5}$/.test(data);

export const testImmatriculation = (data: string) =>
  /^([0-9]{3}[A-Z]{2}[0-9]{2})|([A-Z]{2}[0-9]{3}[A-Z]{2})$/.test(data);

export const parseNumber = (number: string | number) =>
  typeof number !== "number" ? parseFloat(number.replace(/,/g, ".")) : number;
