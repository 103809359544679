import { push } from "connected-react-router";
import { call, delay, put, takeEvery } from "redux-saga/effects";

import routes from "App.routes";
import { logEvent } from "modules/firebase/analytics";
import * as firestore from "modules/firebase/firestore";
import * as request from "services/networking";

import * as actions from "./EmprunteurQuote.actions";

function* submitEmprunteurQuote(action: ReturnType<typeof actions.submitEmprunteurQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<EmprunteurQuote> = yield call(
      request.post<FormEmprunteurValues>("emprunteur/tarifs", formValues),
    );
    logEvent("Demande_de_devis_succes");

    yield put(actions.submitEmprunteurQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formEmprunteur(quote.id)));
  } catch (error: any) {
    yield put(actions.submitEmprunteurQuoteError(error));
  }
}

function* fetchEmprunteurQuote(action: ReturnType<typeof actions.fetchEmprunteurQuote>) {
  try {
    const emprunteurQuoteId = action.payload;

    const quote: WithId<EmprunteurQuote> = yield call(firestore.get(`quotes/${emprunteurQuoteId}`));

    yield put(actions.fetchEmprunteurQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchEmprunteurQuoteError(error));
  }
}

function* updateEmprunteurQuote(action: ReturnType<typeof actions.updateEmprunteurQuote>) {
  try {
    const emprunteurQuote = action.payload;

    yield put(actions.updateEmprunteurQuoteSuccess(emprunteurQuote));
  } catch (error: any) {
    yield put(actions.updateEmprunteurQuoteError(error));
  }
}

function* saveEmprunteurQuote(action: ReturnType<typeof actions.saveEmprunteurQuote>) {
  try {
    const { emprunteurQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`emprunteur/devis/${emprunteurQuoteId}`, tarif));

    yield put(actions.saveEmprunteurQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveEmprunteurQuoteError(error));
  }
}

function* subscribeEmprunteurQuote(action: ReturnType<typeof actions.subscribeEmprunteurQuote>) {
  try {
    const { emprunteurQuoteId, tarif } = action.payload;
    const subscriptionStatus: SubscriptionStatus = yield call(
      request.post<{}>(`emprunteur/souscription/${emprunteurQuoteId}`, tarif),
    );

    yield put(actions.subscribeEmprunteurQuoteSuccess(subscriptionStatus));
  } catch (error: any) {
    yield put(actions.subscribeEmprunteurQuoteError(error));
  }
}

export default function* emprunteurQuoteSaga() {
  yield takeEvery(actions.submitEmprunteurQuote, submitEmprunteurQuote);
  yield takeEvery(actions.fetchEmprunteurQuote, fetchEmprunteurQuote);
  yield takeEvery(actions.updateEmprunteurQuote, updateEmprunteurQuote);
  yield takeEvery(actions.saveEmprunteurQuote, saveEmprunteurQuote);
  yield takeEvery(actions.subscribeEmprunteurQuote, subscribeEmprunteurQuote);
}
