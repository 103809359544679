import { AnalyticsCallOptions, getAnalytics, logEvent as logAnalyticsEvent } from "firebase/analytics";

import { isLocalhost } from "services/navigation";

export const analytics = getAnalytics();

export const logEvent = (
  eventName: string,
  eventParams?: {
    [key: string]: any;
  },
  options?: AnalyticsCallOptions,
) => {
  if (isLocalhost) return;

  return logAnalyticsEvent(analytics, eventName, eventParams, options);
};
