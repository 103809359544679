import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { colors } from "services/style";

export const StyledAnchor = styled.a`
  text-decoration: none;
`;

export const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: ${colors.black};
`;
