import React from "react";

import routes from "App.routes";

import { StyledContainer } from "./Home.style";
import AssuranceCard from "./components/AssuranceCard";

const Home = () => (
  <StyledContainer>
    <AssuranceCard name="Santé" imgUrl="/img/sante.jpg" url={routes.formSante("")} />
    <AssuranceCard name="Prêt-Crédit" imgUrl="/img/credit.jpg" url={routes.formEmprunteur("")} />
    <AssuranceCard name="Habitation" imgUrl="/img/habitation.jpg" url={routes.formHabitation("")} />
    <AssuranceCard name="Auto-Moto" imgUrl="/img/auto-moto.jpg" url={routes.autoMoto()} />
    <AssuranceCard name="Chien-Chats" imgUrl="/img/chats.jpg" url={routes.formAnimaux("")} />
    <AssuranceCard name="Voyage" imgUrl="/img/voyage.jpg" url={routes.formVoyage("")} />
    <AssuranceCard
      name="Surcomplémentaire"
      imgUrl="/img/surcomplementaire.jpg"
      url={routes.formSurcomplementaire("")}
    />
    <AssuranceCard header="Autres" name="Assurances" imgUrl="/img/autres-assurances.jpg" url={routes.autres()} />
  </StyledContainer>
);

export default Home;
