import { push } from "connected-react-router";
import { call, delay, put, takeEvery } from "redux-saga/effects";

import routes from "App.routes";
import { logEvent } from "modules/firebase/analytics";
import * as firestore from "modules/firebase/firestore";
import * as request from "services/networking";

import * as actions from "./SansPermisQuote.actions";

function* submitSansPermisQuote(action: ReturnType<typeof actions.submitSansPermisQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<SansPermisQuote> = yield call(
      request.post<FormSansPermisValues>("sansPermis/tarifs", formValues),
    );
    logEvent("Demande_de_devis_succes");

    yield put(actions.submitSansPermisQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formSansPermis(quote.id)));
  } catch (error: any) {
    yield put(actions.submitSansPermisQuoteError(error));
  }
}

function* fetchSansPermisQuote(action: ReturnType<typeof actions.fetchSansPermisQuote>) {
  try {
    const sansPermisQuoteId = action.payload;

    const quote: WithId<SansPermisQuote> = yield call(firestore.get(`quotes/${sansPermisQuoteId}`));

    yield put(actions.fetchSansPermisQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchSansPermisQuoteError(error));
  }
}

function* updateSansPermisQuote(action: ReturnType<typeof actions.updateSansPermisQuote>) {
  try {
    const sansPermisQuote = action.payload;

    yield put(actions.updateSansPermisQuoteSuccess(sansPermisQuote));
  } catch (error: any) {
    yield put(actions.updateSansPermisQuoteError(error));
  }
}

function* saveSansPermisQuote(action: ReturnType<typeof actions.saveSansPermisQuote>) {
  try {
    const { sansPermisQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`sansPermis/devis/${sansPermisQuoteId}`, tarif));

    yield put(actions.saveSansPermisQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveSansPermisQuoteError(error));
  }
}

export default function* sansPermisQuoteSaga() {
  yield takeEvery(actions.submitSansPermisQuote, submitSansPermisQuote);
  yield takeEvery(actions.fetchSansPermisQuote, fetchSansPermisQuote);
  yield takeEvery(actions.updateSansPermisQuote, updateSansPermisQuote);
  yield takeEvery(actions.saveSansPermisQuote, saveSansPermisQuote);
}
