import { createReducer } from "@reduxjs/toolkit";
import _union from "lodash/union";

import * as actions from "./SanteQuote.actions";

const initialState: SanteQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: "",
  isDeleting: "",
};

export default createReducer<SanteQuoteState>(initialState, {
  [actions.submitSanteQuote.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.submitSanteQuote>,
  ): SanteQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitSanteQuoteError.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.submitSanteQuoteError>,
  ): SanteQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitSanteQuoteSuccess.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.submitSanteQuoteSuccess>,
  ): SanteQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchSanteQuote.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.fetchSanteQuote>,
  ): SanteQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchSanteQuoteError.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.fetchSanteQuoteError>,
  ): SanteQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchSanteQuotesSuccess.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.fetchSanteQuotesSuccess>,
  ): SanteQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<SanteQuote> }>(
      (acc, santeQuote) => ({
        ...acc,
        [santeQuote.id]: santeQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((santeQuote) => santeQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateSanteQuote.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.updateSanteQuote>,
  ): SanteQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateSanteQuoteError.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.updateSanteQuoteError>,
  ): SanteQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.updateSanteQuoteSuccess.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.updateSanteQuoteSuccess>,
  ): SanteQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: "",
  }),
  [actions.saveSanteQuote.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.saveSanteQuote>,
  ): SanteQuoteState => ({
    ...state,
    isUpdating: action.payload.santeQuoteId,
  }),
  [actions.saveSanteQuoteError.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.saveSanteQuoteError>,
  ): SanteQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.saveSanteQuoteSuccess.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.saveSanteQuoteSuccess>,
  ): SanteQuoteState => ({
    ...state,
    isUpdating: "",
  }),
  [actions.subscribeSanteQuote.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.subscribeSanteQuote>,
  ): SanteQuoteState => ({
    ...state,
    isUpdating: action.payload.santeQuoteId,
  }),
  [actions.subscribeSanteQuoteError.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.subscribeSanteQuoteError>,
  ): SanteQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.subscribeSanteQuoteSuccess.type]: (
    state: SanteQuoteState,
    action: ReturnType<typeof actions.subscribeSanteQuoteSuccess>,
  ): SanteQuoteState => ({
    ...state,
    isUpdating: "",
  }),
});
