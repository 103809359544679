import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { Helmet } from "react-helmet";

import CiviliteField from "components/CiviliteField";
import EmailField from "components/EmailField";
import FormRow from "components/FormRow";
import InputField from "components/InputField";
import MobileField from "components/MobileField";
import Recaptcha from "components/Recaptcha";
import { FormContext } from "services/hooks";

import { useContactForm } from "./Contact.hooks";
import { StyledFab, StyledIframe, StyledInputField, StyledPaper } from "./Contact.style";

export interface OwnProps {}

export interface Props extends OwnProps {}

const Contact = (props: Props) => {
  const form = useContactForm();

  return (
    <FormContext.Provider value={form}>
      <Helmet title="Contact" />
      <Container>
        <StyledPaper>
          <Box display="flex" flexDirection="column" flex="2 1 400px">
            <FormRow>
              <Typography gutterBottom variant="h4">
                <b>CONTACT</b>
              </Typography>
              <Typography gutterBottom variant="h5" align="right">
                <b>01 84 20 44 80</b>
              </Typography>
            </FormRow>
            {form.sent ? (
              <Typography gutterBottom variant="h6" align="center">
                <b>Votre message a été envoyé !</b>
              </Typography>
            ) : (
              <>
                <FormRow>
                  <CiviliteField fieldName="civilite" />
                  <InputField
                    label="Nom"
                    fieldName="nom"
                    rules={{
                      required: "Veuillez indiquer votre nom",
                    }}
                  />
                  <InputField
                    label="Prénom"
                    fieldName="prenom"
                    rules={{
                      required: "Veuillez indiquer votre prénom",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <EmailField fieldName="email" />
                  <MobileField style={{ minWidth: "150px" }} fieldName="tel" />
                </FormRow>
                <FormRow>
                  <StyledInputField label="Message" fieldName="message" multiline rows={6} />
                </FormRow>
                <FormRow>
                  <Typography variant="caption" fontSize="0.55rem">
                    <em>
                      Conformément à l’ article L 223-2 du Code de la consommation vous avez la faculté de vous inscrire
                      gratuitement sur une liste d’opposition au démarchage téléphonique
                    </em>
                  </Typography>
                </FormRow>
                <FormRow>
                  <Recaptcha
                    error={form.recaptchaError}
                    onSuccess={(token) => {
                      form.setRecaptchaToken(token);
                      form.setRecaptchaError(false);
                    }}
                  />
                  <StyledFab id="contact-button" variant="extended" onClick={form.onSubmit} disabled={form.loading}>
                    Envoyer
                    <SendIcon />
                  </StyledFab>
                </FormRow>
              </>
            )}
          </Box>
          <StyledIframe
            style={{ flex: "1 1 300px" }}
            title="Adresse AssureCompare"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2621.9274449583413!2d2.3073424161202127!3d48.916774579293204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f15fb282ad3%3A0xf2b68dbc36894bf8!2s4%20Av.%20Laurent%20C%C3%A9ly%20Hall%20C%2C%2092600%20Asni%C3%A8res-sur-Seine!5e0!3m2!1sen!2sfr!4v1661764221307!5m2!1sen!2sfr"
            frameBorder="0"
            height="600px"
          />
        </StyledPaper>
      </Container>
    </FormContext.Provider>
  );
};

export default Contact;
