import { all } from "redux-saga/effects";

import animauxQuoteSaga from "./AnimauxQuote/AnimauxQuote.saga";
import autoQuoteSaga from "./AutoQuote/AutoQuote.saga";
import autoTemporaireQuoteSaga from "./AutoTemporaireQuote/AutoTemporaireQuote.saga";
import campingCarQuoteSaga from "./CampingCarQuote/CampingCarQuote.saga";
import emprunteurQuoteSaga from "./EmprunteurQuote/EmprunteurQuote.saga";
import habitationQuoteSaga from "./HabitationQuote/HabitationQuote.saga";
import loggedUserSaga from "./LoggedUser/LoggedUser.saga";
import loyerImpayeQuoteSaga from "./LoyerImpayeQuote/LoyerImpayeQuote.saga";
import mobileQuoteSaga from "./MobileQuote/MobileQuote.saga";
import motoQuoteSaga from "./MotoQuote/MotoQuote.saga";
import partenaireSaga from "./Partenaire/Partenaire.saga";
import rcProQuoteSaga from "./RCProQuote/RCProQuote.saga";
import sansPermisQuoteSaga from "./SansPermisQuote/SansPermisQuote.saga";
import santeQuoteSaga from "./SanteQuote/SanteQuote.saga";
import surcomplementaireQuoteSaga from "./SurcomplementaireQuote/SurcomplementaireQuote.saga";
import telesurveillanceQuoteSaga from "./TelesurveillanceQuote/TelesurveillanceQuote.saga";
import voyageQuoteSaga from "./VoyageQuote/VoyageQuote.saga";

export default function* rootSaga() {
  yield all([
    loggedUserSaga(),
    rcProQuoteSaga(),
    surcomplementaireQuoteSaga(),
    telesurveillanceQuoteSaga(),
    autoTemporaireQuoteSaga(),
    loyerImpayeQuoteSaga(),
    sansPermisQuoteSaga(),
    campingCarQuoteSaga(),
    mobileQuoteSaga(),
    animauxQuoteSaga(),
    voyageQuoteSaga(),
    habitationQuoteSaga(),
    emprunteurQuoteSaga(),
    santeQuoteSaga(),
    autoQuoteSaga(),
    motoQuoteSaga(),
    partenaireSaga(),
  ]);
}
