import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";

import animauxQuoteReducer from "./AnimauxQuote/AnimauxQuote.reducer";
import autoQuoteReducer from "./AutoQuote/AutoQuote.reducer";
import autoTemporaireQuoteReducer from "./AutoTemporaireQuote/AutoTemporaireQuote.reducer";
import campingCarQuoteReducer from "./CampingCarQuote/CampingCarQuote.reducer";
import emprunteurQuoteReducer from "./EmprunteurQuote/EmprunteurQuote.reducer";
import habitationQuoteReducer from "./HabitationQuote/HabitationQuote.reducer";
import loggedUserReducer from "./LoggedUser/LoggedUser.reducer";
import loyerImpayeQuoteReducer from "./LoyerImpayeQuote/LoyerImpayeQuote.reducer";
import mobileQuoteReducer from "./MobileQuote/MobileQuote.reducer";
import motoQuoteReducer from "./MotoQuote/MotoQuote.reducer";
import partenaireReducer from "./Partenaire/Partenaire.reducer";
import rcProQuoteReducer from "./RCProQuote/RCProQuote.reducer";
import sansPermisQuoteReducer from "./SansPermisQuote/SansPermisQuote.reducer";
import santeQuoteReducer from "./SanteQuote/SanteQuote.reducer";
import surcomplementaireQuoteReducer from "./SurcomplementaireQuote/SurcomplementaireQuote.reducer";
import telesurveillanceQuoteReducer from "./TelesurveillanceQuote/TelesurveillanceQuote.reducer";
import voyageQuoteReducer from "./VoyageQuote/VoyageQuote.reducer";

const createRootReducer = (history: History) =>
  combineReducers({
    rcProQuotes: rcProQuoteReducer,
    surcomplementaireQuotes: surcomplementaireQuoteReducer,
    telesurveillanceQuotes: telesurveillanceQuoteReducer,
    autoTemporaireQuotes: autoTemporaireQuoteReducer,
    loyerImpayeQuotes: loyerImpayeQuoteReducer,
    sansPermisQuotes: sansPermisQuoteReducer,
    campingCarQuotes: campingCarQuoteReducer,
    mobileQuotes: mobileQuoteReducer,
    animauxQuotes: animauxQuoteReducer,
    voyageQuotes: voyageQuoteReducer,
    habitationQuotes: habitationQuoteReducer,
    emprunteurQuotes: emprunteurQuoteReducer,
    santeQuotes: santeQuoteReducer,
    autoQuotes: autoQuoteReducer,
    motoQuotes: motoQuoteReducer,
    partenaires: partenaireReducer,
    loggedUser: loggedUserReducer,
    router: connectRouter(history),
  });

export default createRootReducer;
