import {
  FirestoreDataConverter,
  PartialWithFieldValue,
  Query,
  WithFieldValue,
  addDoc,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  collection as firestoreCollection,
  collectionGroup as firestoreCollectionGroup,
  getDocFromServer,
  getDocsFromServer,
  getFirestore,
  setDoc,
} from "firebase/firestore";

import { isLocalhost } from "services/navigation";

import { firebaseApp } from ".";

const firestore = getFirestore(firebaseApp);

const firestore_port = process.env.REACT_APP_FIRESTORE_EMULATOR_PORT;
if (isLocalhost && firestore_port) {
  console.log(`Connecting to Firestore emulator on  127.0.0.1:${firestore_port}`);
  connectFirestoreEmulator(firestore, "127.0.0.1", Number(firestore_port));
  process.env["FIRESTORE_EMULATOR_HOST"] = `127.0.0.1:${firestore_port}`;
}
const converter = <T extends object>(): FirestoreDataConverter<WithId<T>> => ({
  toFirestore: (withIdData: WithFieldValue<WithId<T>>) => {
    const { id, ...data } = withIdData;

    return data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);

    return {
      ...data,
      id: snapshot.id,
    } as WithId<T>;
  },
});

export const collection = <T extends object>(refPath: string) =>
  firestoreCollection(firestore, refPath).withConverter(converter<T>());
export const collectionGroup = <T extends object>(refPath: string) =>
  firestoreCollectionGroup(firestore, refPath).withConverter(converter<T>());
export const document = <T extends object>(refPath: string) => doc(firestore, refPath).withConverter(converter<T>());

export const add = <T extends object>(refPath: string, value: WithFieldValue<T>) => async () => {
  const docRef = await addDoc(collection<T>(refPath), value);
  const doc = await getDocFromServer(docRef);

  return { ...doc.data(), id: docRef.id };
};

export const get = <T extends object>(refPath: string) => () =>
  getDocFromServer(document<T>(refPath)).then((snapshot) => snapshot.data());

export const set = <T extends object>(refPath: string, value: WithFieldValue<T>) => () =>
  setDoc(document<T>(refPath), value);

export const fetch = <T extends object>(query: Query<T>) => () =>
  getDocsFromServer(query).then((snapshot) => snapshot.docs.map((doc) => doc.data()));

export const update = <T extends object>(refPath: string, value: PartialWithFieldValue<T>) => () =>
  setDoc(
    document<T>(refPath),
    value,
    { merge: true }, // using set + merge to preserve object's structure when calling this function and allow nested delete
  );

export const remove = (refPath: string) => () => deleteDoc(document(refPath));
