import React from "react";
import { DefaultRootState, connect } from "react-redux";
import compose from "recompose/compose";

import Header, { OwnProps, Props } from "./Header.component";

export const mapStateToProps = (state: DefaultRootState) => ({});

export const mapDispatchToProps = {};

export default compose<Props, OwnProps>(connect(mapStateToProps, mapDispatchToProps), React.memo)(Header);
