import { createAction } from "@reduxjs/toolkit";

export const submitSanteQuote = createAction<FormSanteValues>("CREATE_SANTE_QUOTE_REQUEST");
export const submitSanteQuoteSuccess = createAction<WithId<SanteQuote>>("CREATE_SANTE_QUOTE_SUCCESS");
export const submitSanteQuoteError = createAction<Error>("CREATE_SANTE_QUOTE_ERROR");

export const fetchSanteQuote = createAction<string>("FETCH_SANTE_QUOTE_REQUEST");
export const fetchSanteQuoteError = createAction<Error>("FETCH_SANTE_QUOTE_ERROR");
export const fetchSanteQuotesSuccess = createAction<WithId<SanteQuote>[]>("FETCH_SANTE_QUOTES_SUCCESS");

export const updateSanteQuote = createAction<WithId<SanteQuote>>("UPDATE_SANTE_QUOTE_REQUEST");
export const updateSanteQuoteSuccess = createAction<WithId<SanteQuote>>("UPDATE_SANTE_QUOTE_SUCCESS");
export const updateSanteQuoteError = createAction<Error>("UPDATE_SANTE_QUOTE_ERROR");

export const saveSanteQuote = createAction("SAVE_SANTE_QUOTE_REQUEST", (santeQuoteId: string, tarif: Tarif) => ({
  payload: { santeQuoteId, tarif },
}));
export const saveSanteQuoteSuccess = createAction("SAVE_SANTE_QUOTE_SUCCESS");
export const saveSanteQuoteError = createAction<Error>("SAVE_SANTE_QUOTE_ERROR");

export const subscribeSanteQuote = createAction(
  "SUBSCRIBE_SANTE_QUOTE_REQUEST",
  (santeQuoteId: string, tarif: Tarif) => ({ payload: { santeQuoteId, tarif } }),
);
export const subscribeSanteQuoteSuccess = createAction("SUBSCRIBE_SANTE_QUOTE_SUCCESS");
export const subscribeSanteQuoteError = createAction<Error>("SUBSCRIBE_SANTE_QUOTE_ERROR");
