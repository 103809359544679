import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "App.css";
import { ConnectedRouter } from "connected-react-router";
import moment from "moment";
import "moment/locale/fr";
import React from "react";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { firebaseApp } from "modules/firebase";
import { history, persistor, store } from "modules/store";
import AppLayout from "pages/AppLayout";
import theme from "services/theme";

moment.locale("fr");

const App = () => {
  React.useEffect(() => history.listen(() => window.scrollTo(0, 0)), []);

  if (firebaseApp) {
    console.log("Firebase app initialized");
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
              <Helmet
                titleTemplate="%s – AssureCompare"
                defaultTitle="Comparateur d'assurance : le premier qui vous conseille dans vos choix - AssureCompare"
              >
                <meta
                  name="title"
                  content="Comparateur d'assurance : le premier qui vous conseille dans vos choix - AssureCompare"
                />
                <meta
                  name="description"
                  content="Le premier comparateur d'assurance qui vous conseille dans vos choix !"
                />
                <meta
                  property="og:title"
                  content="Comparateur d'assurance : le premier qui vous conseille dans vos choix - AssureCompare"
                />
                <meta
                  property="og:description"
                  content="Le premier comparateur d'assurance qui vous conseille dans vos choix !"
                />
              </Helmet>
              <CssBaseline />
              <AppLayout />
            </ConnectedRouter>
          </ThemeProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
