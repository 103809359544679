import { push } from "connected-react-router";
import { call, delay, put, takeEvery } from "redux-saga/effects";

import routes from "App.routes";
import { logEvent } from "modules/firebase/analytics";
import * as firestore from "modules/firebase/firestore";
import * as request from "services/networking";

import * as actions from "./MobileQuote.actions";

function* submitMobileQuote(action: ReturnType<typeof actions.submitMobileQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<MobileQuote> = yield call(request.post<FormMobileValues>("mobile/tarifs", formValues));
    logEvent("Demande_de_devis_succes");

    yield put(actions.submitMobileQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formMobile(quote.id)));
  } catch (error: any) {
    yield put(actions.submitMobileQuoteError(error));
  }
}

function* fetchMobileQuote(action: ReturnType<typeof actions.fetchMobileQuote>) {
  try {
    const mobileQuoteId = action.payload;

    const quote: WithId<MobileQuote> = yield call(firestore.get(`quotes/${mobileQuoteId}`));
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchMobileQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchMobileQuoteError(error));
  }
}

function* updateMobileQuote(action: ReturnType<typeof actions.updateMobileQuote>) {
  try {
    const mobileQuote = action.payload;

    yield put(actions.updateMobileQuoteSuccess(mobileQuote));
  } catch (error: any) {
    yield put(actions.updateMobileQuoteError(error));
  }
}

function* saveMobileQuote(action: ReturnType<typeof actions.saveMobileQuote>) {
  try {
    const { mobileQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`mobile/devis/${mobileQuoteId}`, tarif));

    yield put(actions.saveMobileQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveMobileQuoteError(error));
  }
}

export default function* mobileQuoteSaga() {
  yield takeEvery(actions.submitMobileQuote, submitMobileQuote);
  yield takeEvery(actions.fetchMobileQuote, fetchMobileQuote);
  yield takeEvery(actions.updateMobileQuote, updateMobileQuote);
  yield takeEvery(actions.saveMobileQuote, saveMobileQuote);
}
