import { createAction } from "@reduxjs/toolkit";

export const submitVoyageQuote = createAction<FormVoyageValues>("CREATE_VOYAGE_QUOTE_REQUEST");
export const submitVoyageQuoteSuccess = createAction<WithId<VoyageQuote>>("CREATE_VOYAGE_QUOTE_SUCCESS");
export const submitVoyageQuoteError = createAction<Error>("CREATE_VOYAGE_QUOTE_ERROR");

export const fetchVoyageQuote = createAction<string>("FETCH_VOYAGE_QUOTE_REQUEST");
export const fetchVoyageQuoteError = createAction<Error>("FETCH_VOYAGE_QUOTE_ERROR");
export const fetchVoyageQuotesSuccess = createAction<WithId<VoyageQuote>[]>("FETCH_VOYAGE_QUOTES_SUCCESS");

export const updateVoyageQuote = createAction<WithId<VoyageQuote>>("UPDATE_VOYAGE_QUOTE_REQUEST");
export const updateVoyageQuoteSuccess = createAction<WithId<VoyageQuote>>("UPDATE_VOYAGE_QUOTE_SUCCESS");
export const updateVoyageQuoteError = createAction<Error>("UPDATE_VOYAGE_QUOTE_ERROR");

export const saveVoyageQuote = createAction("SAVE_VOYAGE_QUOTE_REQUEST", (voyageQuoteId: string, tarif: Tarif) => ({
  payload: { voyageQuoteId, tarif },
}));
export const saveVoyageQuoteSuccess = createAction("SAVE_VOYAGE_QUOTE_SUCCESS");
export const saveVoyageQuoteError = createAction<Error>("SAVE_VOYAGE_QUOTE_ERROR");
