import { createAction } from "@reduxjs/toolkit";

export const submitCampingCarQuote = createAction<FormCampingCarValues>("CREATE_CAMPINGCAR_QUOTE_REQUEST");
export const submitCampingCarQuoteSuccess = createAction<WithId<CampingCarQuote>>("CREATE_CAMPINGCAR_QUOTE_SUCCESS");
export const submitCampingCarQuoteError = createAction<Error>("CREATE_CAMPINGCAR_QUOTE_ERROR");

export const fetchCampingCarQuote = createAction<string>("FETCH_CAMPINGCAR_QUOTE_REQUEST");
export const fetchCampingCarQuoteError = createAction<Error>("FETCH_CAMPINGCAR_QUOTE_ERROR");
export const fetchCampingCarQuotesSuccess = createAction<WithId<CampingCarQuote>[]>("FETCH_CAMPINGCAR_QUOTES_SUCCESS");

export const updateCampingCarQuote = createAction<WithId<CampingCarQuote>>("UPDATE_CAMPINGCAR_QUOTE_REQUEST");
export const updateCampingCarQuoteSuccess = createAction<WithId<CampingCarQuote>>("UPDATE_CAMPINGCAR_QUOTE_SUCCESS");
export const updateCampingCarQuoteError = createAction<Error>("UPDATE_CAMPINGCAR_QUOTE_ERROR");

export const saveCampingCarQuote = createAction(
  "SAVE_CAMPINGCAR_QUOTE_REQUEST",
  (campingCarQuoteId: string, tarif: Tarif) => ({
    payload: { campingCarQuoteId, tarif },
  }),
);
export const saveCampingCarQuoteSuccess = createAction("SAVE_CAMPINGCAR_QUOTE_SUCCESS");
export const saveCampingCarQuoteError = createAction<Error>("SAVE_CAMPINGCAR_QUOTE_ERROR");
