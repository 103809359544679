import { createReducer } from "@reduxjs/toolkit";

import * as actions from "./Partenaire.actions";

const initialState: PartenaireState = {
  logoUrls: [],
};

export default createReducer<PartenaireState>(initialState, {
  [actions.fetchPartenairesError.type]: (
    state: PartenaireState,
    action: ReturnType<typeof actions.fetchPartenairesError>,
  ): PartenaireState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return state;
  },
  [actions.fetchPartenairesSuccess.type]: (
    state: PartenaireState,
    action: ReturnType<typeof actions.fetchPartenairesSuccess>,
  ): PartenaireState => ({
    ...state,
    logoUrls: action.payload,
  }),
});
