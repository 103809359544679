import { createReducer } from "@reduxjs/toolkit";
import _union from "lodash/union";

import * as actions from "./SansPermisQuote.actions";

const initialState: SansPermisQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: "",
  isDeleting: "",
};

export default createReducer<SansPermisQuoteState>(initialState, {
  [actions.submitSansPermisQuote.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.submitSansPermisQuote>,
  ): SansPermisQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitSansPermisQuoteError.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.submitSansPermisQuoteError>,
  ): SansPermisQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitSansPermisQuoteSuccess.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.submitSansPermisQuoteSuccess>,
  ): SansPermisQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchSansPermisQuote.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.fetchSansPermisQuote>,
  ): SansPermisQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchSansPermisQuoteError.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.fetchSansPermisQuoteError>,
  ): SansPermisQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchSansPermisQuotesSuccess.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.fetchSansPermisQuotesSuccess>,
  ): SansPermisQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<SansPermisQuote> }>(
      (acc, sansPermisQuote) => ({
        ...acc,
        [sansPermisQuote.id]: sansPermisQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((sansPermisQuote) => sansPermisQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateSansPermisQuote.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.updateSansPermisQuote>,
  ): SansPermisQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateSansPermisQuoteError.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.updateSansPermisQuoteError>,
  ): SansPermisQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.updateSansPermisQuoteSuccess.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.updateSansPermisQuoteSuccess>,
  ): SansPermisQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: "",
  }),
  [actions.saveSansPermisQuote.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.saveSansPermisQuote>,
  ): SansPermisQuoteState => ({
    ...state,
    isUpdating: action.payload.sansPermisQuoteId,
  }),
  [actions.saveSansPermisQuoteError.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.saveSansPermisQuoteError>,
  ): SansPermisQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.saveSansPermisQuoteSuccess.type]: (
    state: SansPermisQuoteState,
    action: ReturnType<typeof actions.saveSansPermisQuoteSuccess>,
  ): SansPermisQuoteState => ({
    ...state,
    isUpdating: "",
  }),
});
