import { createReducer } from "@reduxjs/toolkit";
import _union from "lodash/union";

import * as actions from "./TelesurveillanceQuote.actions";

const initialState: TelesurveillanceQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: "",
  isDeleting: "",
};

export default createReducer<TelesurveillanceQuoteState>(initialState, {
  [actions.submitTelesurveillanceQuote.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.submitTelesurveillanceQuote>,
  ): TelesurveillanceQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitTelesurveillanceQuoteError.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.submitTelesurveillanceQuoteError>,
  ): TelesurveillanceQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitTelesurveillanceQuoteSuccess.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.submitTelesurveillanceQuoteSuccess>,
  ): TelesurveillanceQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchTelesurveillanceQuote.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.fetchTelesurveillanceQuote>,
  ): TelesurveillanceQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchTelesurveillanceQuoteError.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.fetchTelesurveillanceQuoteError>,
  ): TelesurveillanceQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchTelesurveillanceQuotesSuccess.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.fetchTelesurveillanceQuotesSuccess>,
  ): TelesurveillanceQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<TelesurveillanceQuote> }>(
      (acc, telesurveillanceQuote) => ({
        ...acc,
        [telesurveillanceQuote.id]: telesurveillanceQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((telesurveillanceQuote) => telesurveillanceQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateTelesurveillanceQuote.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.updateTelesurveillanceQuote>,
  ): TelesurveillanceQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateTelesurveillanceQuoteError.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.updateTelesurveillanceQuoteError>,
  ): TelesurveillanceQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.updateTelesurveillanceQuoteSuccess.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.updateTelesurveillanceQuoteSuccess>,
  ): TelesurveillanceQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: "",
  }),
  [actions.saveTelesurveillanceQuote.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.saveTelesurveillanceQuote>,
  ): TelesurveillanceQuoteState => ({
    ...state,
    isUpdating: action.payload.telesurveillanceQuoteId,
  }),
  [actions.saveTelesurveillanceQuoteError.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.saveTelesurveillanceQuoteError>,
  ): TelesurveillanceQuoteState => {
    if (process.env.NODE_ENV !== "production") {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: "",
    };
  },
  [actions.saveTelesurveillanceQuoteSuccess.type]: (
    state: TelesurveillanceQuoteState,
    action: ReturnType<typeof actions.saveTelesurveillanceQuoteSuccess>,
  ): TelesurveillanceQuoteState => ({
    ...state,
    isUpdating: "",
  }),
});
